import "core-js/modules/es.array.push.js";
// Services
import RevenueService from '@/services/statistics/revenueService.js';

// Vue Store
import { mapGetters } from 'vuex';
export default {
  data: function () {
    return {
      color: undefined,
      selectItems: [],
      isLoaded: false,
      isLoading: false,
      loadingText: 'Filteroptionen laden ...'
    };
  },
  props: {
    label: String,
    type: String,
    disabled: Boolean
  },
  methods: {
    loadItems() {
      if (!this.isLoaded) {
        this.isLoading = true;
        switch (this.type) {
          case 'shops':
            {
              RevenueService.getFilterOptions('/company/locations', true).then(res => {
                if (res.status === 200) {
                  res.data.forEach(item => {
                    this.selectItems.push({
                      text: `${item.description} (${item.city})`,
                      value: item.id
                    });
                  });
                  if (res.data.length == 0) {
                    this.loadingText = 'Keine passenden Filteroptionen verfügbar';
                  }
                  this.isLoading = false;
                  this.$store.dispatch('setShops', res.data);
                } else {
                  this.$store.dispatch('setFailMessage', 'Es trat ein Fehler beim Laden ein oder mehrerer Filteroptionen auf');
                  this.$store.dispatch('setErrorDialog', true);
                  console.error(res.stack);
                  this.color = 'error';
                  this.loadingText = 'Keine passenden Filteroptionen verfügbar';
                }
              });
              break;
            }
          case 'addressTypes':
            {
              RevenueService.getFilterOptions('/customer/address/types', false).then(res => {
                if (res.status === 200) {
                  res.data.forEach(item => {
                    //console.log(item)
                    this.selectItems.push({
                      text: item.name,
                      value: item.id
                    });
                  });
                  if (res.data.length == 0) {
                    this.loadingText = 'Keine passenden Filteroptionen verfügbar';
                  }
                  this.isLoading = false;
                  this.$store.dispatch('setAddressTypes', res.data);
                } else {
                  this.$store.dispatch('setFailMessage', 'Es trat ein Fehler beim Laden ein oder mehrerer Filteroptionen auf');
                  this.$store.dispatch('setErrorDialog', true);
                  console.error(res.stack);
                  this.color = 'error';
                  this.loadingText = 'Keine passenden Filteroptionen verfügbar';
                }
              });
              break;
            }
          case 'customerGroups':
            {
              RevenueService.getFilterOptions('/customer/groups', false).then(res => {
                if (res.status === 200) {
                  res.data.forEach(item => {
                    this.selectItems.push({
                      text: item.name,
                      value: item.id
                    });
                  });
                  if (res.data.length == 0) {
                    this.loadingText = 'Keine passenden Filteroptionen verfügbar';
                  }
                  this.isLoading = false;
                  this.$store.dispatch('setCustomerGroups', res.data);
                } else {
                  this.$store.dispatch('setFailMessage', 'Es trat ein Fehler beim Laden ein oder mehrerer Filteroptionen auf');
                  this.$store.dispatch('setErrorDialog', true);
                  console.error(res.stack);
                  this.color = 'error';
                  this.loadingText = 'Keine passenden Filteroptionen verfügbar';
                }
              });
              break;
            }
          case 'articleTypes':
            {
              RevenueService.getFilterOptions('/articles/types', false).then(res => {
                if (res.status === 200) {
                  res.data.forEach(item => {
                    this.selectItems.push({
                      text: `${item.description} (${item.key.tpCode})`,
                      value: item.key.tpCode
                    });
                  });
                  if (res.data.length == 0) {
                    this.loadingText = 'Keine passenden Filteroptionen verfügbar';
                  }
                  this.isLoading = false;
                  this.$store.dispatch('setArticleTypes', res.data);
                } else {
                  this.$store.dispatch('setFailMessage', 'Es trat ein Fehler beim Laden ein oder mehrerer Filteroptionen auf');
                  this.$store.dispatch('setErrorDialog', true);
                  console.error(res.stack);
                  this.color = 'error';
                  this.loadingText = 'Keine passenden Filteroptionen verfügbar';
                }
              });
              break;
            }
          case 'articleCategories':
            {
              RevenueService.getFilterOptions('/articles/categories', true).then(res => {
                if (res.status === 200) {
                  res.data.forEach(item => {
                    this.selectItems.push({
                      text: `${item.description} (${item.key.lfdnr})`,
                      value: item.key.lfdnr
                    });
                  });
                  if (res.data.length == 0) {
                    this.loadingText = 'Keine passenden Filteroptionen verfügbar';
                  }
                  this.isLoading = false;
                  this.$store.dispatch('setArticleCategories', res.data);
                } else {
                  this.$store.dispatch('setFailMessage', 'Es trat ein Fehler beim Laden ein oder mehrerer Filteroptionen auf');
                  this.$store.dispatch('setErrorDialog', true);
                  console.error(res.stack);
                  this.color = 'error';
                  this.loadingText = 'Keine passenden Filteroptionen verfügbar';
                }
              });
              break;
            }
          case 'articleGroups':
            {
              RevenueService.getFilterOptions('/articles/groups', true).then(res => {
                if (res.status === 200) {
                  res.data.forEach(item => {
                    this.selectItems.push({
                      text: `${item.description} (${item.key.lfdnr})`,
                      value: item.key.lfdnr
                    });
                  });
                  if (res.data.length == 0) {
                    this.loadingText = 'Keine passenden Filteroptionen verfügbar';
                  }
                  this.isLoading = false;
                  this.$store.dispatch('setArticleGroups', res.data);
                } else {
                  this.$store.dispatch('setFailMessage', 'Es trat ein Fehler beim Laden ein oder mehrerer Filteroptionen auf');
                  this.$store.dispatch('setErrorDialog', true);
                  console.error(res.stack);
                  this.color = 'error';
                  this.loadingText = 'Keine passenden Filteroptionen verfügbar';
                }
              });
              break;
            }
          case 'statisticGroups':
            {
              RevenueService.getFilterOptions('/articles/groups', false).then(res => {
                if (res.status === 200) {
                  res.data.forEach(item => {
                    this.selectItems.push({
                      text: item.bezeichnung,
                      value: item.code
                    });
                  });
                  if (res.data.length == 0) {
                    this.loadingText = 'Keine passenden Filteroptionen verfügbar';
                  }
                  this.isLoading = false;
                  this.$store.dispatch('setStatisticGroups', res.data);
                } else {
                  this.$store.dispatch('setFailMessage', 'Es trat ein Fehler beim Laden ein oder mehrerer Filteroptionen auf');
                  this.$store.dispatch('setErrorDialog', true);
                  console.error(res.stack);
                  this.color = 'error';
                  this.loadingText = 'Keine passenden Filteroptionen verfügbar';
                }
              });
              break;
            }
          case 'statisticCode':
            {
              RevenueService.getFilterOptions('/articles/codes', false).then(res => {
                if (res.status === 200) {
                  res.data.forEach(item => {
                    this.selectItems.push({
                      text: item.code,
                      value: item.code
                    });
                  });
                  if (res.data.length == 0) {
                    this.loadingText = 'Keine passenden Filteroptionen verfügbar';
                  }
                  this.isLoading = false;
                  this.$store.dispatch('setStatisticCode', res.data);
                } else {
                  this.$store.dispatch('setFailMessage', 'Es trat ein Fehler beim Laden ein oder mehrerer Filteroptionen auf');
                  this.$store.dispatch('setErrorDialog', true);
                  console.error(res.stack);
                  this.color = 'error';
                  this.loadingText = 'Keine passenden Filteroptionen verfügbar';
                }
              });
              break;
            }
          case 'revenueGroups':
            {
              RevenueService.getFilterOptions('/revenue/groups', false).then(res => {
                if (res.status === 200) {
                  res.data.forEach(item => {
                    this.selectItems.push({
                      text: item.name,
                      value: item.id
                    });
                  });
                  if (res.data.length == 0) {
                    this.loadingText = 'Keine passenden Filteroptionen verfügbar';
                  }
                  this.isLoading = false;
                  this.$store.dispatch('setRevenueGroups', res.data);
                } else {
                  this.$store.dispatch('setFailMessage', 'Es trat ein Fehler beim Laden ein oder mehrerer Filteroptionen auf');
                  this.$store.dispatch('setErrorDialog', true);
                  console.error(res.stack);
                  this.color = 'error';
                  this.loadingText = 'Keine passenden Filteroptionen verfügbar';
                }
              });
              break;
            }
          case 'prov_gruppe':
            {
              RevenueService.getFilterOptions('/revenue/provision', false).then(res => {
                if (res.status === 200) {
                  res.data.forEach(item => {
                    this.selectItems.push({
                      text: item.bezeichnung,
                      value: item.code
                    });
                  });
                  if (res.data.length == 0) {
                    this.loadingText = 'Keine passenden Filteroptionen verfügbar';
                  }
                  this.isLoading = false;
                  this.$store.dispatch('setRevenueGroups', res.data);
                } else {
                  this.$store.dispatch('setFailMessage', 'Es trat ein Fehler beim Laden ein oder mehrerer Filteroptionen auf');
                  this.$store.dispatch('setErrorDialog', true);
                  console.error(res.stack);
                  this.color = 'error';
                  this.loadingText = 'Keine passenden Filteroptionen verfügbar';
                }
              });
              break;
            }
          case 'objekt_typ':
            {
              RevenueService.getFilterOptions('/articles/objecttyp', false).then(res => {
                if (res.status === 200) {
                  res.data.forEach(item => {
                    this.selectItems.push({
                      text: item.description,
                      value: item.lfdnr
                    });
                  });
                  if (res.data.length == 0) {
                    this.loadingText = 'Keine passenden Filteroptionen verfügbar';
                  }
                  this.isLoading = false;
                  this.$store.dispatch('setRevenueGroups', res.data);
                } else {
                  this.$store.dispatch('setFailMessage', 'Es trat ein Fehler beim Laden ein oder mehrerer Filteroptionen auf');
                  this.$store.dispatch('setErrorDialog', true);
                  console.error(res.stack);
                  this.color = 'error';
                  this.loadingText = 'Keine passenden Filteroptionen verfügbar';
                }
              });
              break;
            }
          case 'objekt_typ_gruppe':
            {
              RevenueService.getFilterOptions('/articles/objectgrouptyp', false).then(res => {
                if (res.status === 200) {
                  res.data.forEach(item => {
                    this.selectItems.push({
                      text: item.description,
                      value: item.lfdnr
                    });
                  });
                  if (res.data.length == 0) {
                    this.loadingText = 'Keine passenden Filteroptionen verfügbar';
                  }
                  this.isLoading = false;
                  this.$store.dispatch('setRevenueGroups', res.data);
                } else {
                  this.$store.dispatch('setFailMessage', 'Es trat ein Fehler beim Laden ein oder mehrerer Filteroptionen auf');
                  this.$store.dispatch('setErrorDialog', true);
                  console.error(res.stack);
                  this.color = 'error';
                  this.loadingText = 'Keine passenden Filteroptionen verfügbar';
                }
              });
              break;
            }
          case 'expenseGroups':
            {
              RevenueService.getFilterOptions('/expense/groups', false).then(res => {
                if (res.status === 200) {
                  res.data.forEach(item => {
                    this.selectItems.push({
                      text: item.name,
                      value: item.id
                    });
                  });
                  if (res.data.length == 0) {
                    this.loadingText = 'Keine passenden Filteroptionen verfügbar';
                  }
                  this.isLoading = false;
                  this.$store.dispatch('setExpenseGroups', res.data);
                } else {
                  this.$store.dispatch('setFailMessage', 'Es trat ein Fehler beim Laden ein oder mehrerer Filteroptionen auf');
                  this.$store.dispatch('setErrorDialog', true);
                  console.error(res.stack);
                  this.color = 'error';
                  this.loadingText = 'Keine passenden Filteroptionen verfügbar';
                }
              });
              break;
            }
          default:
            {
              this.isLoading = false;
              this.loadingText = 'Keine passenden Filteroptionen verfügbar';
              break;
            }
        }
        this.isLoaded = true;
      }
    },
    apply() {
      this.closeSelect();
    },
    closeSelect() {
      this.$refs.selectStatus.blur();
    }
  },
  mounted() {
    this.loadItems();
  },
  computed: {
    ...mapGetters(['getSelectedFilters']),
    selectedItems: {
      get() {
        return this.getSelectedFilters[this.type] || [];
      },
      set(value) {
        let selectedFilters = this.getSelectedFilters;
        let highestLength = 0;
        selectedFilters[this.type] = value;
        Object.values(selectedFilters).forEach(val => {
          if (val.length > highestLength) {
            highestLength = val.length;
          }
        });

        //eslint-disable
        selectedFilters.total = highestLength === 0 ? true : false;
        this.$store.dispatch('setSelectedFilters', selectedFilters);
        this.$emit('change');
      }
    }
  }
};