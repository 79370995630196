import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VListItem } from 'vuetify/lib/components/VList';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "py-0 my-0 center-filter"
  }, [_c(VAutocomplete, {
    ref: "selectStatus",
    staticClass: "col col-break",
    attrs: {
      "items": _vm.selectItems,
      "label": _vm.label,
      "chips": "",
      "clearable": "",
      "deletable-chips": "",
      "multiple": "",
      "no-data-text": _vm.loadingText,
      "loading": _vm.isLoading,
      "color": _vm.color,
      "disabled": _vm.disabled
    },
    scopedSlots: _vm._u([{
      key: "append-item",
      fn: function () {
        return [_c(VListItem, {
          staticClass: "sticky-button"
        }, [_c(VBtn, {
          attrs: {
            "block": "",
            "color": "primary",
            "large": ""
          },
          on: {
            "click": _vm.apply
          }
        }, [_vm._v(" Anwenden ")])], 1)];
      },
      proxy: true
    }, {
      key: "selection",
      fn: function ({
        item,
        index
      }) {
        return [index === 0 ? _c(VChip, {
          attrs: {
            "small": true
          }
        }, [_c('span', [_vm._v(_vm._s(item.text))])]) : _vm._e(), index === 1 ? _c('span', {
          staticClass: "grey--text text-caption"
        }, [_vm._v(" (+" + _vm._s(_vm.selectedItems.length - 1) + " weitere) ")]) : _vm._e()];
      }
    }]),
    model: {
      value: _vm.selectedItems,
      callback: function ($$v) {
        _vm.selectedItems = $$v;
      },
      expression: "selectedItems"
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };