import { VContainer } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c(VContainer, [_c('fiveYearGraph', {
    attrs: {
      "highestYearInFiveShownYears": _vm.highestYearInFiveShownYears,
      "fiveYearDataArray": _vm.fiveYearDataArray,
      "headLineText": _vm.headLineText,
      "chooseFilter": _vm.chooseFilter,
      "selectYear": _vm.selectYear,
      "entireCustomerInFiveYears": _vm.entireCustomerInFiveYears,
      "activeCustomer": _vm.activeCustomer,
      "inactiveCustomer": _vm.inactiveCustomer
    },
    on: {
      "increaseOneYearInFiveYearChart": _vm.increaseOneYearInFiveYearChart,
      "decreaseOneYearInFiveYearChart": _vm.decreaseOneYearInFiveYearChart,
      "showMonth": _vm.showMonth,
      "showNewCustomers": _vm.showNewCustomers
    }
  }), _vm.singeYearClicked == true ? _c('div', [_c('customerDatatable', {
    attrs: {
      "items": _vm.newCustomerDatatableItems
    }
  })], 1) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };