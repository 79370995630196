var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('RevenueChart', {
    ref: "yearChart",
    attrs: {
      "chartType": "year",
      "chartSeries": _vm.chartSeries,
      "chartYaxisData": _vm.yearLabels,
      "totalSeries": _vm.totalSeries,
      "legendItems": _vm.legendItems,
      "titleText": `Jahresvergleich - ${_vm.getTitle} ${_vm.getUntilCurrentMonth ? 'bis zum aktuellen Monat' : ''}`,
      "xaxisText": "Jahr",
      "yaxisText": _vm.getTitle,
      "stackedChart": true,
      "showBothRevenueTypes": _vm.showBothRevenueTypes
    },
    on: {
      "updateCharts": function ($event) {
        return _vm.$emit('updateCharts');
      }
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };