import { VContainer } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c(VContainer, [_c('div', {
    staticClass: "mx-auto my-12 v-card v-sheet theme--light"
  }, [_c(VTabs, {
    attrs: {
      "grow": "",
      "show-arrows": ""
    },
    model: {
      value: _vm.currentTab,
      callback: function ($$v) {
        _vm.currentTab = $$v;
      },
      expression: "currentTab"
    }
  }, [_c(VTab, {
    attrs: {
      "href": "#documents"
    }
  }, [_vm._v("Dokumentarten")]), _c(VTab, {
    attrs: {
      "href": "#customers"
    }
  }, [_vm._v("Kunden")]), _c(VTab, {
    attrs: {
      "href": "#delivery"
    }
  }, [_vm._v("Lieferanten")])], 1), _c(VTabsItems, {
    model: {
      value: _vm.currentTab,
      callback: function ($$v) {
        _vm.currentTab = $$v;
      },
      expression: "currentTab"
    }
  }, [_c(VTabItem, {
    attrs: {
      "value": "offers"
    }
  }, [_c('Offers')], 1), _c(VTabItem, {
    attrs: {
      "value": "documents"
    }
  }, [_c('Documents')], 1), _c(VTabItem, {
    attrs: {
      "value": "customers"
    }
  }, [_c('Customers')], 1), _c(VTabItem, {
    attrs: {
      "value": "delivery"
    }
  }, [_c('delivery')], 1)], 1)], 1)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };