import "core-js/modules/es.array.push.js";
// Services
import RevenueService from '@/services/statistics/revenueService.js';

// Components
import RevenueChart from '@/components/statistics/Revenue/RevenueChart.vue';

// Vue Store
import { mapGetters } from 'vuex';
export default {
  components: {
    RevenueChart
  },
  props: {
    showBothRevenueTypes: Boolean
  },
  data() {
    return {
      chartSeries: [],
      categoryLabels: [],
      legendItems: [],
      totalSeries: []
    };
  },
  computed: {
    ...mapGetters(['getYearStart', 'getYearEnd', 'getShowBothRevenueTypes', 'getSelectedRevenueTypeShort', 'getSelectedFilters', 'getSelectedCategory', 'getSelectedYear']),
    getNameForCategory() {
      return RevenueService.getDataForCategory(this.getSelectedCategory).name;
    },
    getTitle() {
      return RevenueService.getRevenueName(this.getRevenueTypeShort);
    },
    getRevenueTypeShort() {
      if (this.getShowBothRevenueTypes && this.showBothRevenueTypes) {
        return 'db';
      } else if (this.getShowBothRevenueTypes && !this.showBothRevenueTypes) {
        return 'net';
      }
      return this.getSelectedRevenueTypeShort;
    }
  },
  methods: {
    updateRevenueOfCategory() {
      let chartSeries = [{
        name: this.getTitle,
        data: []
      }];
      let categoryLabels = [];
      let totalSeries = [];
      if (this.getYearEnd < this.getYearStart || this.getYearStart > this.getYearEnd) {
        this.chartSeries = chartSeries;
        this.categoryLabels = categoryLabels;
        this.totalSeries = totalSeries;
        return;
      }
      RevenueService.getRevenueForCategory(this.getSelectedYear, this.getSelectedFilters, this.getSelectedCategory).then(revenues => {
        if (revenues.status === 200) {
          if (!RevenueService.isRevenueResponseValid(revenues)) {
            this.chartSeries = chartSeries;
            this.categoryLabels = categoryLabels;
            this.totalSeries = totalSeries;
            return;
          }
          const categoryData = RevenueService.getDataForCategory(this.getSelectedCategory);
          Object.keys(revenues.data.categoryRevenue).forEach(categoryId => {
            /*console.log({
              categoryId
            })*/
            let categoryStore = this.$store.state.statisticsRevenueTime[this.getSelectedCategory];
            categoryLabels.push(categoryStore.find(element => element[categoryData.idName] == categoryId)[categoryData.valueName]);

            /*console.log({
              categoryId,
              categoryStore,
              categoryLabels,
              categoryData
            })*/
          });
          Object.keys(revenues.data.categoryRevenue).forEach((category, index) => {
            let revenue = revenues.data.categoryRevenue[category];
            totalSeries.push({
              title: categoryLabels[index],
              net: revenue.net,
              db: revenue.db
            });
          });
          Object.keys(revenues.data.categoryRevenue).forEach(id => {
            let revenue = revenues.data.categoryRevenue[id];
            chartSeries[0].data.push(revenue[this.getRevenueTypeShort]);
          });
          this.chartSeries = chartSeries;
          this.categoryLabels = categoryLabels;
          this.totalSeries = totalSeries;
        } else {
          this.$store.dispatch('setFailMessage', 'Es trat ein Fehler beim Laden der Statistik auf: ' + `${this.getNameForCategory}vergleich`);
          this.$store.dispatch('setErrorDialog', true);
          console.error(revenues.stack);
        }
      });
    }
  },
  mounted() {
    if (this.showBothRevenueTypes) {
      this.updateRevenueOfCategory();
    }
  }
};