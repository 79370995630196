import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VChip } from 'vuetify/lib/components/VChip';
import { VChipGroup } from 'vuetify/lib/components/VChipGroup';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c(VCard, [_c('div', {
    staticClass: "mx-auto mb-12 v-card v-sheet theme--light"
  }, [_c('div', {
    staticClass: "mx-4"
  }, [_c(VExpansionPanels, {
    staticClass: "py-4 mx-auto",
    model: {
      value: _vm.filterPanel,
      callback: function ($$v) {
        _vm.filterPanel = $$v;
      },
      expression: "filterPanel"
    }
  }, [_c(VExpansionPanel, [_c(VExpansionPanelHeader, {
    staticClass: "py-0",
    attrs: {
      "expand-icon": "mdi-menu-down"
    }
  }, [_c(VContainer, [_c(VLayout, {
    staticClass: "d-flex align-center",
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('span', {
    staticClass: "mr-2 pt-1"
  }, [_c(VIcon, [_vm._v("mdi-filter-outline")]), _vm._v("Filter")], 1)])], 1)], 1), _c(VExpansionPanelContent, [_c(VRow, {
    staticClass: "d-flex mx-4 no-gutters"
  }, [_c('div', {
    staticClass: "text-h6 font-weight-light d-inline-flex"
  }, [_c(VSelect, {
    staticClass: "me-3 fit yearSelect col-3",
    attrs: {
      "hint": "Anfangsjahr",
      "persistent-hint": "",
      "items": _vm.getYearItems
    },
    on: {
      "change": _vm.updateCharts
    },
    model: {
      value: _vm.yearStart,
      callback: function ($$v) {
        _vm.yearStart = $$v;
      },
      expression: "yearStart"
    }
  }), _c(VIcon, [_vm._v("mdi-minus")]), _c(VSelect, {
    staticClass: "mx-3 fit yearSelect col-3",
    attrs: {
      "hint": "Endjahr",
      "persistent-hint": "",
      "items": _vm.getYearItems
    },
    on: {
      "change": _vm.updateCharts
    },
    model: {
      value: _vm.yearEnd,
      callback: function ($$v) {
        _vm.yearEnd = $$v;
      },
      expression: "yearEnd"
    }
  })], 1), _c(VCheckbox, {
    attrs: {
      "label": "aktuellen Monat inkludieren"
    },
    model: {
      value: _vm.includeCurrentMonth,
      callback: function ($$v) {
        _vm.includeCurrentMonth = $$v;
      },
      expression: "includeCurrentMonth"
    }
  })], 1), _c(VDivider, {
    staticClass: "mt-6"
  }), _c(VRow, {
    staticClass: "d-flex no-gutters mt-6 mb-2"
  }, [_c(VCol, {
    staticClass: "col-break"
  }, [_c('FilterSelect', {
    attrs: {
      "label": "Filialen",
      "type": "shops",
      "disabled": false
    },
    on: {
      "change": _vm.updateCharts
    }
  }), _c('FilterSelect', {
    attrs: {
      "label": "Adressarten",
      "type": "addressTypes",
      "disabled": false
    },
    on: {
      "change": _vm.updateCharts
    }
  }), _c('FilterSelect', {
    attrs: {
      "label": "Kundengruppen",
      "type": "customerGroups",
      "disabled": false
    },
    on: {
      "change": _vm.updateCharts
    }
  })], 1), _c(VDivider, {
    staticClass: "mx-4",
    attrs: {
      "vertical": true
    }
  }), _c(VCol, {
    staticClass: "col-break"
  }, [_c('FilterSelect', {
    attrs: {
      "label": "Artikeltypen",
      "type": "articleTypes",
      "disabled": false
    },
    on: {
      "change": _vm.updateCharts
    }
  }), _c('FilterSelect', {
    attrs: {
      "label": "Artikelkategorien",
      "type": "articleCategories",
      "disabled": false
    },
    on: {
      "change": _vm.updateCharts
    }
  }), _c('FilterSelect', {
    attrs: {
      "label": "Artikelgruppen",
      "type": "articleGroups",
      "disabled": false
    },
    on: {
      "change": _vm.updateCharts
    }
  })], 1), _c(VDivider, {
    staticClass: "mx-4",
    attrs: {
      "vertical": true
    }
  }), _c(VCol, {
    staticClass: "col-break"
  }, [_c('FilterSelect', {
    attrs: {
      "label": "Statistikgruppen",
      "type": "statisticGroups",
      "disabled": false
    },
    on: {
      "change": _vm.updateCharts
    }
  }), _c('FilterSelect', {
    attrs: {
      "label": "Statistikcode",
      "type": "statisticCode",
      "disabled": true
    },
    on: {
      "change": _vm.updateCharts
    }
  }), _c('FilterSelect', {
    attrs: {
      "label": "Provisionsgruppen",
      "type": "commissionGroups",
      "disabled": true
    },
    on: {
      "change": _vm.updateCharts
    }
  })], 1), _c(VDivider, {
    staticClass: "mx-4",
    attrs: {
      "vertical": true
    }
  }), _c(VCol, {
    staticClass: "col-break"
  }, [_c('FilterSelect', {
    attrs: {
      "label": "Erlösgruppen",
      "type": "revenueGroups",
      "disabled": false
    },
    on: {
      "change": _vm.updateCharts
    }
  }), _c('FilterSelect', {
    attrs: {
      "label": "Aufwandsgruppen",
      "type": "expenseGroups",
      "disabled": false
    },
    on: {
      "change": _vm.updateCharts
    }
  }), _c('FilterSelect', {
    attrs: {
      "label": "Fibukonten",
      "type": "fibuAccounts",
      "disabled": true
    },
    on: {
      "change": _vm.updateCharts
    }
  })], 1)], 1), _c(VRow, {
    staticClass: "d-flex justify-end pb-2"
  }, [_c(VBtn, {
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        $event.preventDefault();
        return _vm.resetFilter.apply(null, arguments);
      }
    }
  }, [_c(VIcon, {
    staticClass: "pe-2",
    attrs: {
      "color": "error"
    }
  }, [_vm._v(" mdi-cancel")]), _vm._v(" Alle Filter zurücksetzen ")], 1)], 1)], 1)], 1)], 1), _c(VRow, [_c(VCol, {
    staticClass: "col-3 mx-auto col-break",
    attrs: {
      "id": "selectedFilters"
    }
  }, [_c(VCard, [_c(VCardTitle, [_c(VCol, {
    staticClass: "col-12"
  }, [_vm._v("Aktive Filter")]), _c(VSelect, {
    staticClass: "me-3 fit yearSelect col-12",
    attrs: {
      "hint": "Jahr für Filterstatistik",
      "persistent-hint": "",
      "items": _vm.getYearItems
    },
    on: {
      "change": _vm.updateCategoryCharts
    },
    model: {
      value: _vm.selectedYear,
      callback: function ($$v) {
        _vm.selectedYear = $$v;
      },
      expression: "selectedYear"
    }
  }), _c(VSelect, {
    staticClass: "me-3 fit yearSelect col-12",
    attrs: {
      "hint": "Kategorie für Filterstatistik",
      "persistent-hint": "",
      "items": _vm.getCategorySelection
    },
    on: {
      "change": _vm.updateCategoryCharts
    },
    model: {
      value: _vm.selectedCategory,
      callback: function ($$v) {
        _vm.selectedCategory = $$v;
      },
      expression: "selectedCategory"
    }
  })], 1), _c(VCardText, [_vm.getSelectedFilters.total ? _c(VList, [_c(VListItem, [_vm._v("Keine Filter sind aktiv")])], 1) : _c(VList, _vm._l(_vm.selectedFilterCategories, function (filterType, index) {
    return _c(VListItem, {
      key: index,
      attrs: {
        "two-line": ""
      }
    }, [_c(VListItemContent, [_c(VListItemTitle, [_vm._v(_vm._s(_vm.getNameForCategory(filterType)))]), _c(VListItemSubtitle, {
      staticClass: "wrap-text"
    }, [_c(VChipGroup, {
      attrs: {
        "column": ""
      }
    }, _vm._l(_vm.selectedFilterNames[filterType], function (filterItem, index) {
      return _c(VChip, {
        key: index,
        attrs: {
          "small": "",
          "close": "",
          "close-icon": "mdi-delete"
        },
        on: {
          "click": function ($event) {
            return _vm.removeFilter(filterType, index);
          },
          "click:close": function ($event) {
            return _vm.removeFilter(filterType, index);
          }
        }
      }, [_vm._v(_vm._s(filterItem))]);
    }), 1)], 1)], 1)], 1);
  }), 1)], 1)], 1)], 1), _c(VCol, {
    staticClass: "col-9 col-break"
  }, [_c('ChartControls', {
    on: {
      "updateCharts": _vm.updateCharts
    }
  }), !_vm.getShowBothRevenueTypes ? _c(VRow, [_c(VCol, {
    attrs: {
      "cols": 12
    }
  }, [_c('YearChart', {
    ref: "yearChart",
    staticClass: "d-flex",
    attrs: {
      "showBothRevenueTypes": false
    },
    on: {
      "updateCharts": _vm.updateCharts
    }
  })], 1)], 1) : _c(VRow, [_c(VCol, {
    attrs: {
      "cols": 6
    }
  }, [_c('YearChart', {
    ref: "yearChart",
    staticClass: "d-flex",
    attrs: {
      "showBothRevenueTypes": false
    },
    on: {
      "updateCharts": _vm.updateCharts
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": 6
    }
  }, [_c('YearChart', {
    ref: "yearChartExtra",
    staticClass: "d-flex",
    attrs: {
      "showBothRevenueTypes": true
    },
    on: {
      "updateCharts": _vm.updateCharts
    }
  })], 1)], 1), _c('ChartControls', {
    on: {
      "updateCharts": _vm.updateCharts
    }
  }), !_vm.getShowBothRevenueTypes ? _c(VRow, [_c(VCol, {
    attrs: {
      "cols": 12
    }
  }, [_c('MonthChart', {
    ref: "monthChart",
    staticClass: "d-flex",
    attrs: {
      "showBothRevenueTypes": false
    },
    on: {
      "updateCharts": _vm.updateCharts
    }
  })], 1)], 1) : _c(VRow, [_c(VCol, {
    attrs: {
      "cols": 6
    }
  }, [_c('MonthChart', {
    ref: "monthChart",
    staticClass: "d-flex",
    attrs: {
      "showBothRevenueTypes": false
    },
    on: {
      "updateCharts": _vm.updateCharts
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": 6
    }
  }, [_c('MonthChart', {
    ref: "monthChartExtra",
    staticClass: "d-flex",
    attrs: {
      "showBothRevenueTypes": true
    },
    on: {
      "updateCharts": _vm.updateCharts
    }
  })], 1)], 1), !_vm.getShowBothRevenueTypes ? _c(VRow, [_c(VCol, {
    attrs: {
      "cols": 12
    }
  }, [_c('CategoryChart', {
    ref: "categoryChart",
    staticClass: "d-flex",
    attrs: {
      "showBothRevenueTypes": false
    },
    on: {
      "updateCharts": _vm.updateCharts
    }
  })], 1)], 1) : _c(VRow, [_c(VCol, {
    attrs: {
      "cols": 6
    }
  }, [_c('CategoryChart', {
    ref: "categoryChart",
    staticClass: "d-flex",
    attrs: {
      "showBothRevenueTypes": false
    },
    on: {
      "updateCharts": _vm.updateCharts
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": 6
    }
  }, [_c('CategoryChart', {
    ref: "categoryChartExtra",
    staticClass: "d-flex",
    attrs: {
      "showBothRevenueTypes": true
    },
    on: {
      "updateCharts": _vm.updateCharts
    }
  })], 1)], 1)], 1)], 1)], 1)]), _c('ErrorDialog', {
    attrs: {
      "failMessage": _vm.getFailMessage,
      "opened": _vm.getErrorDialog
    },
    on: {
      "closeDialog": function ($event) {
        return _vm.$store.dispatch('setErrorDialog', false);
      }
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };