import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c(VContainer, [_c('div', {
    staticClass: "filter-container",
    attrs: {
      "id": "FilterContainer"
    }
  }, [_c('div', {
    staticClass: "filter-item"
  }, [_c('div', {
    staticClass: "form-control-container"
  }, [_c('div', {
    staticClass: "form-control-container__time"
  }, [_vm._v("StartYear")]), _c(VSelect, {
    ref: "yearChart",
    attrs: {
      "hint": "Anfangsjahr",
      "persistent-hint": "",
      "items": _vm.generateYearsOptions()
    },
    on: {
      "change": _vm.offerGraph
    },
    model: {
      value: _vm.yearStartSelection,
      callback: function ($$v) {
        _vm.yearStartSelection = $$v;
      },
      expression: "yearStartSelection"
    }
  })], 1)]), _c('div', {
    staticClass: "filter-item"
  }, [_c('div', {
    staticClass: "form-control-container"
  }, [_c('div', {
    staticClass: "form-control-container__time"
  }, [_vm._v("EndYear")]), _c(VSelect, {
    ref: "yearChart",
    attrs: {
      "hint": "Endjahr",
      "persistent-hint": "",
      "items": _vm.generateYearsOptions()
    },
    on: {
      "change": _vm.offerGraph
    },
    model: {
      value: _vm.yearEndSelection,
      callback: function ($$v) {
        _vm.yearEndSelection = $$v;
      },
      expression: "yearEndSelection"
    }
  })], 1)])]), _c('div', {
    staticClass: "filter-container",
    attrs: {
      "id": "FilterContainer"
    }
  }, [_c('div', {
    staticClass: "filter-item"
  }, [_c('div', {
    staticClass: "form-control-container"
  }, [_c(VTextField, {
    attrs: {
      "label": "Netto Wert Minimum",
      "placeholder": "nur Zahlen",
      "type": "number",
      "min": "0"
    },
    on: {
      "change": _vm.offerGraph
    },
    model: {
      value: _vm.min_netto,
      callback: function ($$v) {
        _vm.min_netto = $$v;
      },
      expression: "min_netto"
    }
  })], 1)]), _c('div', {
    staticClass: "filter-item"
  }, [_c('div', {
    staticClass: "form-control-container"
  }, [_c(VTextField, {
    attrs: {
      "label": "Netto Wert Maximum",
      "placeholder": "nur Zahlen",
      "type": "number",
      "min": "0"
    },
    on: {
      "change": _vm.offerGraph
    },
    model: {
      value: _vm.max_netto,
      callback: function ($$v) {
        _vm.max_netto = $$v;
      },
      expression: "max_netto"
    }
  })], 1)]), _c('div', {
    staticClass: "filter-item"
  }, [_c('div', {
    staticClass: "form-control-container"
  }, [_c(VTextField, {
    attrs: {
      "label": "Anzahl Minimum",
      "placeholder": "nur Zahlen",
      "type": "number",
      "min": "0"
    },
    on: {
      "change": _vm.offerGraph
    },
    model: {
      value: _vm.minBetragSelection,
      callback: function ($$v) {
        _vm.minBetragSelection = $$v;
      },
      expression: "minBetragSelection"
    }
  })], 1)]), _c('div', {
    staticClass: "filter-item"
  }, [_c('div', {
    staticClass: "form-control-container"
  }, [_c(VTextField, {
    attrs: {
      "label": "Anzahl Maximum",
      "placeholder": "nur Zahlen",
      "type": "number",
      "min": "0"
    },
    on: {
      "change": _vm.offerGraph
    },
    model: {
      value: _vm.maxBetragSelection,
      callback: function ($$v) {
        _vm.maxBetragSelection = $$v;
      },
      expression: "maxBetragSelection"
    }
  })], 1)])]), _c('br'), _c('div', {
    staticClass: "outer-container rounded"
  }, [_c('h3', [_vm._v("Artikelfilter")]), _c('br'), _c(VRow, [_c(VAutocomplete, {
    staticClass: "mx-sm-4 mx-lg-3",
    attrs: {
      "filled": "",
      "items": _vm.articleTypes,
      "item-text": "description",
      "return-object": "",
      "label": "Artikeltype",
      "clearable": ""
    },
    model: {
      value: _vm.art_type,
      callback: function ($$v) {
        _vm.art_type = $$v;
      },
      expression: "art_type"
    }
  })], 1), _c(VRow, [_c(VCol, [_c(VAutocomplete, {
    attrs: {
      "items": _vm.articleCategories,
      "item-text": "description",
      "return-object": "",
      "label": "Artikelkategorie",
      "clearable": "",
      "filled": ""
    },
    model: {
      value: _vm.art_kategorie,
      callback: function ($$v) {
        _vm.art_kategorie = $$v;
      },
      expression: "art_kategorie"
    }
  })], 1), _c(VCol, [_c(VAutocomplete, {
    attrs: {
      "filled": "",
      "items": _vm.articleGroups,
      "item-text": "description",
      "return-object": "",
      "label": "Artikelgruppe",
      "clearable": ""
    },
    model: {
      value: _vm.art_gruppe,
      callback: function ($$v) {
        _vm.art_gruppe = $$v;
      },
      expression: "art_gruppe"
    }
  })], 1)], 1), _c(VBtn, {
    on: {
      "click": _vm.addArticle
    }
  }, [_vm._v("Anwenden")]), _c('br'), _c('br'), _vm._l(_vm.filterArticleName, function (item, index) {
    return _c(VChip, {
      key: item.id,
      attrs: {
        "clearable": "",
        "item": item,
        "index": index,
        "closable": "",
        "close": ""
      },
      on: {
        "click:close": function ($event) {
          return _vm.articleClose(index);
        }
      }
    });
  })], 2), _c('div', {
    staticClass: "d-flex justify-end"
  }, [_c(VBtn, {
    attrs: {
      "icon": "",
      "color": "black"
    },
    on: {
      "click": _vm.yearBack
    }
  }, [_c(VIcon, [_vm._v("mdi-arrow-left-thick")])], 1), _c(VBtn, {
    attrs: {
      "icon": "",
      "color": "black"
    },
    on: {
      "click": _vm.yearForward
    }
  }, [_c(VIcon, [_vm._v("mdi-arrow-right-thick")])], 1)], 1), _c('div', {
    attrs: {
      "id": "chartContainer"
    }
  }, [_c('div', {
    attrs: {
      "id": "graph1"
    }
  }, [_c('h3', [_vm._v("Angebote")]), _c('apexchart', {
    ref: "totalChart1",
    attrs: {
      "type": "bar",
      "height": "350",
      "options": _vm.offerGraphOptions,
      "series": _vm.offerGraphSeries
    }
  })], 1), _c('div', {
    attrs: {
      "id": "graph2"
    }
  }, [_c('h3', [_vm._v("Angebote Umsatz / DB")]), _c('apexchart', {
    ref: "totalChart2",
    attrs: {
      "type": "bar",
      "height": "350",
      "options": _vm.offerGraph2Options,
      "series": _vm.offerGraph2Series
    }
  })], 1)])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };