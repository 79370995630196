/*
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
    setup() {
        
    },
})
*/

import Offers from '@/components/statistics/customerDevelopment/yearComparisonOffer.vue';
import Customers from '@/components/statistics/customerDevelopment/totalDevelopmentNew.vue';
import Delivery from '@/components/statistics/customerDevelopment/delivery.vue';
import Documents from '@/components/statistics/customerDevelopment/documentTypes.vue';
export default {
  components: {
    Offers,
    Documents,
    Customers,
    Delivery
  },
  data: () => ({
    currentTab: ''
  })
};