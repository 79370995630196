import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c(VContainer, [_c('div', [_c('h2', [_vm._v(" " + _vm._s(_vm.headLineText) + " "), _c('span', {
    staticClass: "subtitle-1"
  }, [_vm._v(_vm._s(this.yearStartSelection) + "-" + _vm._s(this.yearEndSelection))])])]), _c(VExpansionPanels, {
    staticClass: "py-4 mx-auto",
    model: {
      value: _vm.filterPanel,
      callback: function ($$v) {
        _vm.filterPanel = $$v;
      },
      expression: "filterPanel"
    }
  }, [_c(VExpansionPanel, [_c(VExpansionPanelHeader, {
    staticClass: "py-0",
    attrs: {
      "expand-icon": "mdi-menu-down"
    }
  }, [_c(VContainer, [_c(VLayout, {
    staticClass: "d-flex align-center",
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('span', {
    staticClass: "mr-2 pt-1"
  }, [_c(VIcon, [_vm._v("mdi-filter-outline")]), _vm._v("Filter")], 1)])], 1)], 1), _c(VExpansionPanelContent, [_c('div', {
    directives: [{
      name: "-if",
      rawName: "v--if",
      value: !_vm.isCollapsed,
      expression: "!isCollapsed"
    }],
    staticClass: "filter-content"
  }, [_c('div', {
    staticClass: "filter-container",
    attrs: {
      "id": "FilterContainer"
    }
  }, [_c('div', {
    staticClass: "filter-item"
  }, [_c('div', {
    staticClass: "form-control-container"
  }, [_c(VSelect, {
    ref: "yearChart",
    attrs: {
      "hint": "Anfangsjahr",
      "persistent-hint": "",
      "items": _vm.generateYearsOptions()
    },
    on: {
      "change": _vm.startdocumentTypesAndofferGraphs
    },
    model: {
      value: _vm.yearStartSelection,
      callback: function ($$v) {
        _vm.yearStartSelection = $$v;
      },
      expression: "yearStartSelection"
    }
  })], 1)]), _c('div', {
    staticClass: "filter-item"
  }, [_c('div', {
    staticClass: "form-control-container"
  }, [_c(VSelect, {
    ref: "yearChart",
    attrs: {
      "hint": "Endjahr",
      "persistent-hint": "",
      "items": _vm.generateYearsOptions()
    },
    on: {
      "change": _vm.startdocumentTypesAndofferGraphs
    },
    model: {
      value: _vm.yearEndSelection,
      callback: function ($$v) {
        _vm.yearEndSelection = $$v;
      },
      expression: "yearEndSelection"
    }
  })], 1)])]), _c('div', {
    attrs: {
      "id": "DocumentGroups"
    }
  }, [_c(VSelect, {
    staticClass: "mb-5 mt-3",
    attrs: {
      "items": _vm.documentGroup,
      "item-text": "form_bezeichnung",
      "item-value": "form_gruppe",
      "label": "Dokumentgruppen",
      "chips": "",
      "clearable": "",
      "deletable-chips": "",
      "single": ""
    },
    on: {
      "change": _vm.startdocumentTypesAndofferGraphs
    },
    model: {
      value: _vm.selectedDocumentGroup,
      callback: function ($$v) {
        _vm.selectedDocumentGroup = $$v;
      },
      expression: "selectedDocumentGroup"
    }
  }, [_c(VChip, {
    attrs: {
      "close": ""
    }
  })], 1)], 1), _c('br'), _c('div', {
    staticClass: "outer-container rounded"
  }, [_c('h3', [_vm._v("Artikelfilter")]), _c('br'), _c(VRow, [_c(VAutocomplete, {
    staticClass: "mx-sm-4 mx-lg-3",
    attrs: {
      "items": _vm.articleTypes,
      "item-text": "description",
      "return-object": "",
      "label": "Artikeltyp",
      "clearable": ""
    },
    model: {
      value: _vm.art_type,
      callback: function ($$v) {
        _vm.art_type = $$v;
      },
      expression: "art_type"
    }
  })], 1), _c(VRow, [_c(VCol, [_c(VAutocomplete, {
    attrs: {
      "items": _vm.articleCategories,
      "item-text": "description",
      "return-object": "",
      "label": "Artikelkategorie",
      "clearable": ""
    },
    model: {
      value: _vm.art_kategorie,
      callback: function ($$v) {
        _vm.art_kategorie = $$v;
      },
      expression: "art_kategorie"
    }
  })], 1), _c(VCol, [_c(VAutocomplete, {
    attrs: {
      "items": _vm.articleGroups,
      "item-text": "description",
      "return-object": "",
      "label": "Artikelgruppe",
      "clearable": ""
    },
    model: {
      value: _vm.art_gruppe,
      callback: function ($$v) {
        _vm.art_gruppe = $$v;
      },
      expression: "art_gruppe"
    }
  })], 1)], 1), _c(VRow, [_c(VCol, [_c(VAutocomplete, {
    attrs: {
      "items": _vm.statisticGroups,
      "item-text": "description",
      "return-object": "",
      "label": "Statistikgruppe",
      "clearable": ""
    },
    model: {
      value: _vm.statistic_gruppe,
      callback: function ($$v) {
        _vm.statistic_gruppe = $$v;
      },
      expression: "statistic_gruppe"
    }
  })], 1), _c(VCol, [_c(VAutocomplete, {
    attrs: {
      "items": _vm.statisticCodes,
      "item-text": "code",
      "return-object": "",
      "label": "Statistikcode",
      "clearable": ""
    },
    model: {
      value: _vm.statistic_code,
      callback: function ($$v) {
        _vm.statistic_code = $$v;
      },
      expression: "statistic_code"
    }
  })], 1)], 1), _c(VRow, [_c(VCol, [_c(VAutocomplete, {
    attrs: {
      "items": _vm.commissionGroups,
      "item-text": "bezeichnung",
      "return-object": "",
      "label": "Provisionsgruppe",
      "clearable": ""
    },
    model: {
      value: _vm.prov_gruppe,
      callback: function ($$v) {
        _vm.prov_gruppe = $$v;
      },
      expression: "prov_gruppe"
    }
  })], 1), _c(VCol, [_c(VAutocomplete, {
    attrs: {
      "items": _vm.revenueGroups,
      "item-text": "name",
      "return-object": "",
      "label": "Erlösgruppe",
      "clearable": ""
    },
    model: {
      value: _vm.erloes_gruppe,
      callback: function ($$v) {
        _vm.erloes_gruppe = $$v;
      },
      expression: "erloes_gruppe"
    }
  })], 1)], 1), _c(VRow, [_c(VCol, [_c(VAutocomplete, {
    attrs: {
      "items": _vm.objectTypes,
      "item-text": "description",
      "return-object": "",
      "label": "Objekttyp",
      "clearable": ""
    },
    model: {
      value: _vm.objekt_typ,
      callback: function ($$v) {
        _vm.objekt_typ = $$v;
      },
      expression: "objekt_typ"
    }
  })], 1), _c(VCol, [_c(VAutocomplete, {
    attrs: {
      "items": _vm.objectTypeGroups,
      "item-text": "description",
      "return-object": "",
      "label": "Objekttypengruppe",
      "clearable": ""
    },
    model: {
      value: _vm.objekt_typ_gruppe,
      callback: function ($$v) {
        _vm.objekt_typ_gruppe = $$v;
      },
      expression: "objekt_typ_gruppe"
    }
  })], 1)], 1), _c(VBtn, {
    on: {
      "click": _vm.addArticle
    }
  }, [_vm._v("Anwenden")]), _c('br'), _c('br')], 1)])])], 1)], 1), _c('br'), _c('h3', [_vm._v("Platzhalter für aktive Filterfenster")]), _c('div', {
    attrs: {
      "id": "graph"
    }
  }, [_c('div', {
    staticClass: "graph-container"
  }, [_c('h3', [_vm._v(" " + _vm._s(_vm.headlineTextJahresver + this.getDocumentGroupName(this.selectedDocumentGroup)) + " ")]), _c(VBtn, {
    attrs: {
      "icon": "",
      "color": "black"
    },
    on: {
      "click": _vm.yearBack
    }
  }, [_c(VIcon, [_vm._v("mdi-arrow-left-thick")])], 1), _c(VBtn, {
    attrs: {
      "icon": "",
      "color": "black"
    },
    on: {
      "click": _vm.yearForward
    }
  }, [_c(VIcon, [_vm._v("mdi-arrow-right-thick")])], 1)], 1), _c('apexchart', {
    ref: "totalChart",
    staticClass: "apexchart",
    attrs: {
      "type": "bar",
      "height": "350",
      "options": _vm.documentTypesOptions,
      "series": _vm.documentTypesSeries
    }
  })], 1), _c('div', {
    attrs: {
      "id": "chartContainer"
    }
  }, [_c('div', {
    attrs: {
      "id": "graph1"
    }
  }, [_c('h3', [_vm._v(" " + _vm._s(_vm.headlineTextDokumentanz + this.getDocumentGroupName(this.selectedDocumentGroup)) + " ")]), _c('apexchart', {
    ref: "totalChart1",
    attrs: {
      "type": "bar",
      "height": "350",
      "options": _vm.offerGraphOptions,
      "series": _vm.offerGraphSeries
    }
  })], 1), _c('div', {
    attrs: {
      "id": "graph2"
    }
  }, [_c('h3', [_vm._v(" " + _vm._s(_vm.headlineTextUmsatzDb + this.getDocumentGroupName(this.selectedDocumentGroup)) + " ")]), _c('apexchart', {
    ref: "totalChart2",
    attrs: {
      "type": "bar",
      "height": "350",
      "options": _vm.offerGraph2Options,
      "series": _vm.offerGraph2Series
    }
  })], 1)])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };