var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('RevenueChart', {
    ref: "categoryChart",
    attrs: {
      "chartType": "category",
      "chartSeries": _vm.chartSeries,
      "chartYaxisData": _vm.categoryLabels,
      "totalSeries": _vm.totalSeries,
      "legendItems": _vm.legendItems,
      "titleText": `${_vm.getNameForCategory}vergleich - ${_vm.getTitle}: ${_vm.getSelectedYear}`,
      "xaxisText": _vm.getNameForCategory,
      "yaxisText": _vm.getTitle,
      "stackedChart": false,
      "showBothRevenueTypes": _vm.showBothRevenueTypes
    },
    on: {
      "updateCharts": function ($event) {
        return _vm.$emit('updateCharts');
      }
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };