import "core-js/modules/es.array.push.js";
// Services
import RevenueService from '@/services/statistics/revenueService.js';

// Components
import ErrorDialog from '@/components/core/ErrorDialog.vue';
import FilterSelect from '@/components/statistics/Revenue/FilterSelect.vue';
import ChartControls from '@/components/statistics/Revenue/ChartControls.vue';
import YearChart from '@/components/statistics/Revenue/YearChart.vue';
import MonthChart from '@/components/statistics/Revenue/MonthChart.vue';
import CategoryChart from '@/components/statistics/Revenue/CategoryChart.vue';

// Vue Store
import { mapGetters } from 'vuex';
export default {
  components: {
    ErrorDialog,
    FilterSelect,
    ChartControls,
    YearChart,
    MonthChart,
    CategoryChart
  },
  data() {
    return {
      filterPanel: 0
    };
  },
  mounted() {
    this.init();
  },
  computed: {
    ...mapGetters(['getMinYear', 'getYearItems', 'getYearStart', 'getYearEnd', 'getIncludeCurrentMonth', 'getShowBothRevenueTypes', 'getSelectedFilters', 'getSelectedCategory', 'getSelectedYear', 'getFailMessage', 'getErrorDialog']),
    yearStart: {
      get() {
        return this.getYearStart;
      },
      set(value) {
        this.$store.dispatch('setYearStart', value);
      }
    },
    yearEnd: {
      get() {
        return this.getYearEnd;
      },
      set(value) {
        this.$store.dispatch('setYearEnd', value);
      }
    },
    includeCurrentMonth: {
      get() {
        return this.getIncludeCurrentMonth;
      },
      set(value) {
        this.$store.dispatch('setIncludeCurrentMonth', value);
      }
    },
    selectedFilterCategories() {
      let selectedFilterCategories = [];
      Object.keys(this.getSelectedFilters).forEach((key, index) => {
        if (index != 0 && this.getSelectedFilters[key].length != 0) {
          selectedFilterCategories.push(key);
        }
      });
      return selectedFilterCategories;
    },
    selectedFilterNames() {
      const returnObject = {};
      Object.entries(this.getSelectedFilters).forEach(([key, value], index) => {
        if (index !== 0 && value.length !== 0) {
          const categoryData = RevenueService.getDataForCategory(key);
          returnObject[key] = value.map(selectedFilter => {
            return this.$store.state.statisticsRevenueTime[key].find(element => element[categoryData.idName] === selectedFilter)[categoryData.valueName];
          });
        }
      });
      return returnObject;
    },
    selectedYear: {
      get() {
        return this.getSelectedYear;
      },
      set(value) {
        this.$store.dispatch('setSelectedYear', value);
        this.updateCategoryCharts();
      }
    },
    selectedCategory: {
      get() {
        return this.getSelectedCategory;
      },
      set(value) {
        this.$store.dispatch('setSelectedCategory', value);
        this.updateCategoryCharts();
      }
    },
    getCategorySelection: {
      get() {
        let categorySelectionItems = [];
        Object.keys(this.getSelectedFilters).forEach((category, index) => {
          if (index !== 0) {
            categorySelectionItems.push({
              text: RevenueService.getDataForCategory(category).name,
              value: category
            });
          }
        });
        return categorySelectionItems;
      }
    }
  },
  methods: {
    init() {
      if (this.$route.query.from !== undefined && this.$route.query.to !== undefined) {
        this.$store.dispatch('setYearStart', parseInt(this.$route.query.from));
        this.$store.dispatch('setYearEnd', parseInt(this.$route.query.to));
      } else {
        this.$router.replace({
          query: {
            ...this.$route.query,
            from: this.yearStart,
            to: this.yearEnd
          }
        });
      }
      RevenueService.getMinimumYearForRevenue().then(res => {
        if (res.status === 200) {
          this.$store.dispatch('setMinYear', res.data);
          const items = [];
          for (let i = this.getMinYear; i <= new Date().getFullYear(); i++) {
            items.push(i);
          }
          this.$store.dispatch('setYearItems', items);
        } else {
          this.$store.dispatch('setFailMessage', 'Es trat ein Fehler beim Laden der Statistik auf: minimales Jahr');
          this.$store.dispatch('setErrorDialog', true);
          console.error(res.stack);
        }
      });
      this.$store.dispatch('setSelectedCategory', Object.keys(this.getSelectedFilters)[1]);
      this.updateCharts();
    },
    resetFilter() {
      this.$store.dispatch('setYearStart', new Date().getFullYear() - 2);
      this.$store.dispatch('setYearEnd', new Date().getFullYear());
      this.$store.dispatch('setIncludeCurrentMonth', true);
      this.$store.dispatch('setSelectedFilters', {
        total: true,
        shops: [],
        addressTypes: [],
        customerGroups: [],
        articleTypes: [],
        articleCategories: [],
        articleGroups: [],
        statisticGroups: [],
        statisticCode: [],
        commissionGroups: [],
        revenueGroups: [],
        expenseGroups: [],
        fibuAccounts: []
      });
      this.$store.dispatch('setSelectedYear', new Date().getFullYear());
      this.$store.dispatch('setSelectedCategory', Object.keys(this.getSelectedFilters)[1]);
      this.$store.dispatch('setSelectedRevenueTypeBool', false);
      this.$store.dispatch('setSelectedRevenueTypeShort', 'net');
      this.$store.dispatch('setShowBothRevenueTypes', false);
      this.$store.dispatch('setUntilCurrentMonth', false);
      this.updateCharts();
    },
    updateCharts() {
      this.$refs.yearChart.updateRevenueOfYearSpan();
      this.$refs.monthChart.updateShownMonth();
      this.$refs.categoryChart.updateRevenueOfCategory();
      if (this.getShowBothRevenueTypes) {
        this.$refs.yearChartExtra.updateRevenueOfYearSpan();
        this.$refs.monthChartExtra.updateShownMonth();
        this.$refs.categoryChartExtra.updateRevenueOfCategory();
      }
    },
    updateCategoryCharts() {
      this.$refs.categoryChart.updateRevenueOfCategory();
      if (this.getShowBothRevenueTypes) {
        this.$refs.categoryChartExtra.updateRevenueOfCategory();
      }
    },
    removeFilter(filterType, index) {
      const selectedFilters = {
        ...this.getSelectedFilters
      };
      let flag = true;
      selectedFilters[filterType].splice(index, 1);
      Object.keys(selectedFilters).forEach((key, index) => {
        if (index != 0) {
          if (selectedFilters[key].length > 0) {
            flag = false;
          }
        }
      });
      selectedFilters.total = flag;
      this.$store.dispatch('setSelectedFilters', selectedFilters);
      this.updateCharts();
    },
    getNameForCategory(filterType) {
      return RevenueService.getDataForCategory(filterType).name;
    }
  }
};