import "core-js/modules/es.array.push.js";
import customerDevelopmentService from '@/services/statistics/customerDevelopmentService.js';
import { mapState, mapGetters } from 'vuex';
export default {
  created() {
    this.offerGraph();
    this.generateYearsOptions();
    this.loadData();
  },
  beforeMount() {
    this.loadData();
    this.offerGraph();
  },
  mounted() {
    this.loadData();
    this.offerGraph();
  },
  computed: {
    ...mapState({
      //filtersActive: state => state.crmFilter.filterActivated,
      articleTypes: state => state.articleFilter.articleTypes,
      articleCategories: state => state.articleFilter.articleCategories,
      articleGroups: state => state.articleFilter.articleGroups
      //filter: state => state.articleFilter.filter,
      //visibleTabs: state => state.articlePermissions.visibleTabs
      //foundArticles: state => state.articleFilter.foundArticles
    }),
    ...mapGetters(['getFlter'])
  },
  data: function () {
    return {
      CustomerDetailsYear: new Date().getFullYear() - 1,
      yearStartSelection: new Date().getFullYear() - 4,
      yearEndSelection: new Date().getFullYear(),
      offerAll: [],
      offerOpen: [],
      offerDB: [],
      offerUM: [],
      tDB: [],
      tUM: [],
      art_kategorie: '',
      art_type: '',
      art_gruppe: '',
      filterArticle: [],
      filterArticleName: [],
      offerGraph2Series: {},
      offerGraphSeries: {},
      offerGraphOptions: {
        chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: false
          },
          events: {
            click: (event, chartContext, config) => {
              this.$emit('showNewCustomers', config.dataPointIndex);
            }
          }
        },
        dataLabels: {
          enabled: true
        },
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'],
            opacity: 0.5
          }
        },
        xaxis: {
          categories: []
        },
        noData: {
          text: 'Keine Daten verfügbar'
        }
      },
      offerGraph2Options: {
        chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: false
          },
          events: {
            click: (event, chartContext, config) => {
              this.$emit('showNewCustomers', config.dataPointIndex);
            }
          }
        },
        dataLabels: {
          enabled: false
        },
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'],
            opacity: 0.5
          }
        },
        xaxis: {
          categories: []
        },
        yaxis: {
          labels: {
            formatter: function (value) {
              const formatter = new Intl.NumberFormat('de-DE', {
                style: 'currency',
                currency: 'EUR',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              });
              return formatter.format(value).replace(/\s/g, '');
            }
          }
        },
        noData: {
          text: 'Keine Daten verfügbar'
        }
      }
    };
  },
  methods: {
    articleClose(index) {
      this.filterArticle.splice(index, 1);
      this.filterArticleName.splice(index, 1);
      this.offerGraph();
    },
    offerGraph() {
      customerDevelopmentService.getOffers(this.yearStartSelection, this.yearEndSelection, this.min_netto, this.max_netto, this.minBetragSelection, this.maxBetragSelection, this.filterArticle).then(response => {
        response.data.forEach(data => {
          this.offerAll.push(data.tCount);
          this.offerOpen.push(data.oCount);
          this.offerDB.push(data.oDb);
          this.offerUM.push(data.oUm);
          this.tDB.push(data.tDb);
          this.tUM.push(data.tUm);
        });
        this.updateChart1();
        this.updateChart2();
      }).catch(error => {
        console.error(error);
      });
    },
    updateChart1() {
      const startYear = this.yearStartSelection;
      const endYear = this.yearEndSelection;
      const xAxisCategories = [];
      for (let year = startYear; year <= endYear; year++) {
        xAxisCategories.push(year.toString());
      }
      this.offerGraphOptions.xaxis.categories = xAxisCategories;
      this.$refs.totalChart1.updateSeries([{
        name: 'Anzahl',
        data: this.offerAll
      }, {
        name: 'Offen',
        data: this.offerOpen
      }]), this.$refs.totalChart1.updateOptions({
        xaxis: {
          categories: xAxisCategories
        }
      });
      this.offerAll = [];
      this.offerOpen = [];
    },
    updateChart2() {
      const startYear = this.yearStartSelection;
      const endYear = this.yearEndSelection;
      const xAxisCategories = [];
      for (let year = startYear; year <= endYear; year++) {
        xAxisCategories.push(year.toString());
      }
      this.$refs.totalChart2.updateSeries([{
        name: 'Offener Deckungsbetrag',
        group: 'Gesamt',
        data: this.offerDB
      }, {
        name: 'Offener Umsatz',
        data: this.offerUM
      }, {
        name: 'Gesamter Deckungsbetrag',
        data: this.tDB
      }, {
        name: 'Gesamter Umsatz',
        data: this.tUM
      }]), this.$refs.totalChart2.updateOptions({
        xaxis: {
          categories: xAxisCategories
        }
      });
      this.tDB = [];
      this.tUM = [];
      this.offerDB = [];
      this.offerUM = [];
    },
    addArticle() {
      if ((this.art_kategorie == '' || this.art_kategorie == null) && (this.art_type == '' || this.art_type == null) && (this.art_gruppe == '' || this.art_gruppe == null)) {
        return;
      }
      let artkat = '';
      let arttyp = '';
      let artgrp = '';
      let artkatn = '';
      let arttypn = '';
      let artgrpn = '';
      if (this.art_kategorie != '' && this.art_kategorie != null) {
        artkat = this.art_kategorie.key.lfdnr;
        artkatn = this.art_kategorie.description;
      }
      if (this.art_type != '' && this.art_type != null) {
        arttyp = this.art_type.key.tpCode;
        arttypn = this.art_type.description;
      }
      if (this.art_gruppe != '' && this.art_gruppe != null) {
        artgrp = this.art_gruppe.key.lfdnr;
        artgrpn = this.art_gruppe.description;
      }
      this.filterArticle.push({
        art_kategorie: artkat,
        art_type: arttyp,
        art_gruppe: artgrp
      });
      this.filterArticleName.push({
        art_kategorie: artkatn,
        art_type: arttypn,
        art_gruppe: artgrpn
      });
      this.art_gruppe = '';
      this.art_type = '';
      this.art_kategorie = '';
      this.offerGraph();
    },
    generateYearsOptions() {
      let currentYear = new Date().getFullYear();
      let options = [];
      for (let i = currentYear; i >= 2010; i--) {
        options.push({
          text: i.toString(),
          value: i
        });
      }
      return options;
    },
    yearBack() {
      let yearStart = this.yearStartSelection;
      if (yearStart != this.getMinYear) {
        if (this.getSelectedYear > this.yearEndSelection - 1) {
          this.$store.dispatch('setSelectedYear', this.getSelectedYear - 1);
        }
        this.yearStartSelection = yearStart - 1;
        this.yearEndSelection -= 1;
        this.offerGraph();
      }
    },
    yearForward() {
      let yearEnd = this.yearEndSelection;
      if (yearEnd != this.getCurrentYear) {
        if (this.getSelectedYear < this.yearStartSelection + 1) {
          this.$store.dispatch('setSelectedYear', this.getSelectedYear + 1);
        }
        this.yearStartSelection += 1;
        this.yearEndSelection = yearEnd + 1;
        this.offerGraph();
      }
    },
    loadData() {
      this.$store.dispatch('loadArticleTypes');
      this.$store.dispatch('loadArticleCategories');
      this.$store.dispatch('loadArticleGroups');
      //this.$store.dispatch('loadVisibleTabs')

      const storedFilter = this.$store.getters.getFlter;
      this.articleType = storedFilter.articleType;
      this.articleCategory = storedFilter.articleCategory;
      this.articleGroup = storedFilter.articleGroup;
    }
  }
};