// Vue Store
import { mapGetters } from 'vuex';
export default {
  computed: {
    ...mapGetters(['getMinYear', 'getYearStart', 'getYearEnd', 'getUntilCurrentMonth', 'getSelectedRevenueTypeBool', 'getShowBothRevenueTypes', 'getSelectedRevenueTypeShort']),
    selectedRevenueTypeBool: {
      get() {
        return this.getSelectedRevenueTypeBool;
      },
      set(value) {
        this.$store.dispatch('setSelectedRevenueTypeBool', value);
        this.$store.dispatch('setSelectedRevenueTypeShort', value ? 'db' : 'net');
        this.$emit('updateCharts');
      }
    },
    showBothRevenueTypes: {
      get() {
        return this.getShowBothRevenueTypes;
      },
      set(value) {
        this.$store.dispatch('setShowBothRevenueTypes', value);
        this.$emit('updateCharts');
      }
    }
  },
  methods: {
    yearBack() {
      let yearStart = this.getYearStart;
      if (yearStart != this.getMinYear) {
        this.$store.dispatch('setYearStart', yearStart - 1);
        this.$store.dispatch('setYearEnd', this.getYearEnd - 1);
        this.$emit('updateCharts');
      }
    },
    yearForward() {
      let yearEnd = this.getYearEnd;
      if (yearEnd != new Date().getFullYear()) {
        this.$store.dispatch('setYearStart', this.getYearStart + 1);
        this.$store.dispatch('setYearEnd', yearEnd + 1);
        this.$emit('updateCharts');
      }
    },
    toggleUntilCurrentMonth() {
      this.$store.dispatch('setUntilCurrentMonth', !this.getUntilCurrentMonth);
      this.$emit('updateCharts');
    }
  }
};